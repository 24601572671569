export default {
    getItem: state => field => {
        if(typeof(state[field]) !== 'undefined') {
            return state[field]    
        }
        return '';
    },
    getPreviousChatList: state => ({ id, type}) => {
        if(typeof(state['previousChatList'][type]) != 'undefined' && typeof(state['previousChatList'][type][id]) != 'undefined') {
            return state['previousChatList'][type][id];    
        }
        return [];
    },
    getPreviousChatItem: state => ({ id, type, chatId}) => {
        let chatItem = false;
        if(typeof(state['previousChatList'][type]) != 'undefined' && typeof(state['previousChatList'][type][id]) != 'undefined') {
            let chatList = state['previousChatList'][type][id];
            chatList.every( item => {
                if(item.id == chatId) {
                    chatItem = item;
                    return false;
                }
                return true;
            });
        }
        return chatItem;
    },

}