<template>
    <textarea v-bind="$attrs" :value="value" v-on="inputListeners"></textarea>
</template>

<script>
    export default {
        name: "SaiTextArea",
        props: {
            value: String
        },
        inheritAttrs: false,
        model: {
            prop: 'value',
            event: 'input'
        },
        computed: {
            inputListeners: function () {
                var vm = this;
                return Object.assign({},
                    // We add all the listeners from the parent
                    this.$listeners,
                    {
                        // This ensures that the component works with v-model
                        input: function (event) {
                            vm.$emit('input', event.target.value)
                        }
                    }
                )
            }
        }
    }
</script>

<style scoped>

</style>