export default {
    getItem: state => field => {
        const keys =  field.split(".");
        let currentLevel = state;
        for (let i = 0; i < keys.length; i++) {
            let key = keys[i];
            
            // If the key doesn't exist at the current level, create an empty object
            if (!currentLevel.hasOwnProperty(key)) {
                return '';
            }
            
            // If it's the last key, set the value
            if (i === keys.length - 1) {
                return currentLevel[key];
            }
            
            // Move the current level deeper into the nested structure
            currentLevel = currentLevel[key];
        }
        return '';
    },
    showTOCSelectoin: (state, getters) => {
        return getters.getItem('showTOCSelection')  && !getters.getItem('loading');
    },
    showCompareSummary: (state, getters) => {
        return getters.getItem('showCompareSummary')  && !getters.getItem('loading');
    },
    showCopyButton: (state, getters) => {
        return getters.getItem('showCompareSummary') && !getters.getItem('loading');
    },
    showBackButton: (state, getters) => {
        let notFirstScreen  = !getters.getItem('showTOCError') && !getters.getItem('initLoading') && !getters.getItem('showTOCSelection');
        let errorOrOtherScreens =  getters.getItem('showCompareSummary') || getters.getItem('showError') || getters.getItem('loading');
        return (notFirstScreen &&  errorOrOtherScreens);
    },
    showCompareButton: (state, getters) => {
        return getters.getItem('showTOCSelection') && !getters.getItem('loading'); 
    },
    activateCompareButton: (state, getters) => {
        return getters.getItem('showTOCSelection') && !getters.getItem('loading')
        && (typeof getters.getItem('toc1Selection').id !== "undefined") && (typeof getters.getItem('toc2Selection').id !== "undefined");
    },
    showRatingOption: (state, getters) => {
        return getters.getItem('showCompareSummary') && !getters.getItem('loading');
    },
    showRegenerateSummary: (state, getters) => {
        return getters.getItem('showCompareSummary') && !getters.getItem('loading');
    }
}