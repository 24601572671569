import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {localize} from "vee-validate";
import axios from '@/plugins/axios';

Vue.use(VueI18n);

async function apiGetDBTranslations(file, showKeys) {
    try {
        const response = await axios.get({endpoint: '/management/translation/getJson/' + file + '/' + showKeys});
        if (response.data) {
            return response.data;
        } else {
            return [];
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
}

// Load the translation mode.
const wxtranslationmodeRaw = window.wxtranslationmode || "";

let wxtranslationmode = "";
if (typeof (wxtranslationmodeRaw) != "undefined" && wxtranslationmodeRaw !== '') {
    wxtranslationmode = JSON.parse(Buffer.from(wxtranslationmodeRaw, "base64"))
}

const wxtranslationkeysRaw = window.wxtranslationkeys || "";

let wxtranslationkeys = "";
if (typeof (wxtranslationkeysRaw) != "undefined" && wxtranslationkeysRaw !== '') {
    wxtranslationkeys = JSON.parse(Buffer.from(wxtranslationkeysRaw, "base64"))
}

const i18n = new VueI18n({
    locale: 'english', // set locale
    fallbackLocale: 'english',
    messages: {}
})

const loadedLanguages = [] // our default language that is preloaded

function setI18nLanguage(lang) {
    i18n.locale = lang

    // Load language file from api call.
    if (wxtranslationmode === 1) {
        apiGetDBTranslations('vue-validations', wxtranslationkeys).then(
            validationLocale => {
                localize(lang, validationLocale)
            }
        );
    } else {
        import(`@/locales/validations/${lang}.json`).then(
            validationLocale => {
                localize(lang, validationLocale)
            }
        );
    }

    return lang
}

export const loadLanguageAsync = function (lang) {

    // If the language was already loaded
    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    if (wxtranslationmode === 1) {
        // Load language file from api call.
        apiGetDBTranslations('vue-webshop', wxtranslationkeys).then(messages => {
            i18n.setLocaleMessage(lang, messages)
            loadedLanguages.push(lang)
            return setI18nLanguage(lang)
        });
    } else {
        // If the language hasn't been loaded yet
        return import(/* webpackChunkName: "lang-[request]" */ `@/locales/${lang}.json`).then(
            messages => {
                i18n.setLocaleMessage(lang, messages.default)
                loadedLanguages.push(lang)
                return setI18nLanguage(lang)
            }
        )
    }

}

export default i18n;

export const initializeLang = () => {
    const availableLanguages = ['english','french','german','italian','spanish','russian','chinese'];
    let initLang = (typeof window.wxlanguage === 'undefined') ? 'english' : atob(window.wxlanguage);
    if(availableLanguages.indexOf(initLang.toLowerCase()) < 0) {
        initLang = 'english';
    }
    loadLanguageAsync(initLang);
}

export const asyncLoadValidationMsgs = path => {
    import(`@/components/${path}.json`).then(validationMessages => {
        localize(i18n.locale, validationMessages);
    });
}