import Mutation from './mutationTypes';
import {
    apiSendMessageToAI,
    apiLoadPreviousChatList,
    apiDeleteChatHistory,
    apiGenerateChatTitle
} from "@/components/AIChat/api"

export default {
    setItem: ({ commit }, {field, value}) => {
        commit(Mutation.SET_ITEM, {field, value});
    },
    sendMessageToAI: async ({ commit, state, getters }, message) => {
        try {
            let params = {
                docId: state.docId,
                itemId: state.itemId,
                userId: state.userId,
                chatId: state.chatId,
                resId: state.resId,
                pubId: state.pubId,
                message
            };
            commit(Mutation.SET_ADD_CHAT_MESSAGE, {role: 'user', content: message});
            commit(Mutation.SET_ITEM, {field: 'waitingReply', value: true});
            const reply =  await apiSendMessageToAI(params);
            commit(Mutation.SET_ADD_CHAT_MESSAGE, {role: 'assistant', content: reply.aiMessage, citations: reply.citations });
            commit(Mutation.SET_ITEM, {field: 'chatId', value: reply.chatId});
            commit(Mutation.SET_ITEM, {field: 'waitingReply', value: false});
            let chatObjExists = getters.getPreviousChatItem({id: state.itemId, type: 'item', chatId: reply.chatId});
            if(chatObjExists === false) { //new chat
                let chatTile = message.substr(0, 30) + '...';
                state.chatMessages.forEach(item => {
                    item.chat_id = reply.chatId;
                });
                let newChatItem = {
                    id: reply.chatId,
                    item_id: state.itemId,
                    doc_id: state.docId,
                    user_id: state.userId,
                    res_id: state.resId,
                    pub_id: state.pubId,
                    newTitle: chatTile,
                    messages:  state.chatMessages
                }
                commit(Mutation.ADD_CHAT_ITEM_TO_PREVIOUS_CHAT_LIST, { id: state.itemId, type: 'item', chatItem: newChatItem });
            } else {
                if(state.chatMessages.length > 3 && !chatObjExists.title) {
                    let chatTitle =  await apiGenerateChatTitle(reply.chatId);
                    commit(Mutation.UPDATE_CHAT_ITEM_TITLE, { itemId: state.itemId, type: 'item', chatId: reply.chatId, title: chatTitle })
                } 
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'waitingReply', value: false});
            console.log('api call failed for getting reply');
            console.log(error);
        }
    },
    loadNewChat: async ({ commit }, chat) => {
        commit(Mutation.SET_ITEM, {field: 'chatId', value: ''});    
        commit(Mutation.SET_ITEM, {field: 'itemId', value: ''});    
        commit(Mutation.SET_ITEM, {field: 'docId', value: ''});    
        commit(Mutation.SET_ITEM, {field: 'userId', value: ''});    
        commit(Mutation.SET_ITEM, {field: 'resId', value: ''});    
        commit(Mutation.SET_ITEM, {field: 'pubId', value: ''});    
        commit(Mutation.SET_ITEM, {field: 'chatMessages', value: []});    
        if(typeof(chat.chatId ) != 'undefined' && chat.chatId != null && chat.chatId != '') {
            commit(Mutation.SET_ITEM, {field: 'chatId', value: chat.chatId});    
        } 
        if(typeof(chat.itemId ) != 'undefined' && chat.itemId != null && chat.itemId != '') {
            commit(Mutation.SET_ITEM, {field: 'itemId', value: chat.itemId});    
        }
        if(typeof(chat.docId ) != 'undefined' && chat.docId != null && chat.docId != '') {
            commit(Mutation.SET_ITEM, {field: 'docId', value: chat.docId});    
        }
        if(typeof(chat.userId ) != 'undefined' && chat.userId != null && chat.userId != '') {
            commit(Mutation.SET_ITEM, {field: 'userId', value: chat.userId});    
        }
        if(typeof(chat.resId ) != 'undefined' && chat.resId != null && chat.resId != '') {
            commit(Mutation.SET_ITEM, {field: 'resId', value: chat.resId});    
        }
        if(typeof(chat.pubId ) != 'undefined' && chat.pubId != null && chat.pubId != '') {
            commit(Mutation.SET_ITEM, {field: 'pubId', value: chat.pubId});    
        }
        commit(Mutation.SET_ITEM, {field: 'showPreviousChatList', value: false});
        commit(Mutation.SET_ITEM, {field: 'showChatMessageWindow', value: true});
    },
    showPreviousChatList: ({ commit }) => {
        commit(Mutation.SET_ITEM, {field: 'showPreviousChatList', value: true});
        commit(Mutation.SET_ITEM, {field: 'showChatMessageWindow', value: false});
    },
    loadPreviousChatList: async ({ commit, getters }, filters) => {
        try {
            let previousChatList = getters.getPreviousChatList({ id: filters.itemId,  type: filters.type})
            if(previousChatList.length == 0) {
                commit(Mutation.SET_ITEM, {field: 'loading', value: true});
                const prevousChatListLoaded =  await apiLoadPreviousChatList(filters);
                // commit(Mutation.SET_ITEM, {field: 'previousChatList', value: previousChatList});
                commit(Mutation.SET_PREVIOUS_CHAT_LIST, {id: filters.itemId, previousChatList: prevousChatListLoaded, type: filters.type});
                commit(Mutation.SET_ITEM, {field: 'showPreviousChatList', value: true});
                commit(Mutation.SET_ITEM, {field: 'showChatMessageWindow', value: false});
                commit(Mutation.SET_ITEM, {field: 'loading', value: false});
            } else {
                commit(Mutation.SET_ITEM, {field: 'showPreviousChatList', value: true});
                commit(Mutation.SET_ITEM, {field: 'showChatMessageWindow', value: false});
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'showPreviousChatList', value: false});
            commit(Mutation.SET_ITEM, {field: 'showChatMessageWindow', value: true});
            commit(Mutation.SET_ITEM, {field: 'loading', value: false});
            console.log('api call failed for getting previous chat list');
            console.log(error);
        }
    },
    loadPreviousChat: ({ commit }, chat) => {
        commit(Mutation.SET_ITEM, {field: 'loading', value: true});
        if(typeof(chat.id ) != 'undefined' && chat.id != null && chat.id != '') {
            commit(Mutation.SET_ITEM, {field: 'chatId', value: chat.id});    
        }
        if(typeof(chat.item_id ) != 'undefined' && chat.item_id != null && chat.item_id != '') {
            commit(Mutation.SET_ITEM, {field: 'itemId', value: chat.item_id});    
        }
        if(typeof(chat.doc_id ) != 'undefined' && chat.doc_id != null && chat.doc_id != '') {
            commit(Mutation.SET_ITEM, {field: 'docId', value: chat.doc_id});    
        }
        if(typeof(chat.user_id ) != 'undefined' && chat.user_id != null && chat.user_id != '') {
            commit(Mutation.SET_ITEM, {field: 'userId', value: chat.user_id});    
        }
        if(typeof(chat.res_id ) != 'undefined' && chat.res_id != null && chat.res_id != '') {
            commit(Mutation.SET_ITEM, {field: 'resId', value: chat.res_id});    
        }
        if(typeof(chat.pub_id ) != 'undefined' && chat.pub_id != null && chat.pub_id != '') {
            commit(Mutation.SET_ITEM, {field: 'pubId', value: chat.pub_id});    
        }
        if(typeof(chat.messages ) != 'undefined' && chat.messages != null && chat.messages != '') {
            commit(Mutation.SET_ITEM, {field: 'chatMessages', value: chat.messages});    
        }
        commit(Mutation.SET_ITEM, {field: 'showPreviousChatList', value: false});
        commit(Mutation.SET_ITEM, {field: 'showChatMessageWindow', value: true});
        commit(Mutation.SET_ITEM, {field: 'loading', value: false});
    },
    initChat: async ({ commit, dispatch, getters }, chatDetails) => {
        if(typeof(chatDetails.userId ) != 'undefined' && chatDetails.userId != null && chatDetails.userId != '') {
            commit(Mutation.SET_ITEM, {field: 'showMainChatWindow', value: true});    
            commit(Mutation.SET_ITEM, {field: 'showPreviousChatList', value: false});
            commit(Mutation.SET_ITEM, {field: 'showChatMessageWindow', value: false});
            commit(Mutation.SET_ITEM, {field: 'loading', value: true});    
            await dispatch('loadPreviousChatList', chatDetails);
            
            let previousChatList = [];
            if(chatDetails.type == 'generic' && typeof(chatDetails.chatId ) != 'undefined' && chatDetails.chatId != null && chatDetails.chatId != '') {
                previousChatList = getters.getPreviousChatList({id: chatDetails.chatId, type: chatDetails.type})
            } else if(chatDetails.type == 'item' && typeof(chatDetails.itemId ) != 'undefined' && chatDetails.itemId != null && chatDetails.itemId != '') {
                previousChatList = getters.getPreviousChatList({id: chatDetails.itemId, type: chatDetails.type})
            } else if(chatDetails.type == 'doc' && typeof(chatDetails.docId ) != 'undefined' && chatDetails.docId != null && chatDetails.docId != '') {
                previousChatList = getters.getPreviousChatList({id: chatDetails.docId, type: chatDetails.type})
            } else if(chatDetails.type == 'user') {
                previousChatList = getters.getPreviousChatList({id: chatDetails.userId, type:  chatDetails.type});
            } 
            if(typeof(chatDetails.resId ) != 'undefined' && chatDetails.resId != null && chatDetails.resId != '') {
                commit(Mutation.SET_ITEM, {field: 'resId', value: chatDetails.resId});
            }
            if(typeof(chatDetails.pubId ) != 'undefined' && chatDetails.pubId != null && chatDetails.pubId != '') {
                commit(Mutation.SET_ITEM, {field: 'pubId', value: chatDetails.pubId});
            }

            if(previousChatList.length) {
                dispatch('loadPreviousChat', previousChatList[0]);
            } else {
                dispatch('loadNewChat', chatDetails);
            }
            commit(Mutation.SET_ITEM, {field: 'showChatMessageWindow', value: true});
            commit(Mutation.SET_ITEM, {field: 'loading', value: false});    
        }
    },
    closeChat:  ({ commit }) => {
        commit(Mutation.SET_ITEM, {field: 'showMainChatWindow', value: false});    
    },
    deleteChatHistory: async ({ state, commit }, chatId) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'loading', value: true});
            const deletedSuccess =  await apiDeleteChatHistory(chatId);
            if(deletedSuccess) {
                if(chatId ==  state.chatId) {
                    commit(Mutation.SET_ITEM, {field: 'chatId', value: ''});    
                    commit(Mutation.SET_ITEM, {field: 'chatMessages', value: []});    
                }
                commit(Mutation.REMOVE_PREVIOUS_CHAT, { id: state.itemId, type: 'item', chatId });
                commit(Mutation.SET_ITEM, {field: 'loading', value: false});
            } else {
                commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                console.log('delete failed');
            }
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'loading', value: false});
            console.log('api call failed for deleting chat history');
            console.log(error);
        }  
    }
}