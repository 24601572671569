import Mutation from '@/components/AIChat/store/mutationTypes';
const addNewMessageSideEffects = store => {
    store.subscribe((mutation) => {
        if (mutation.type === ("aichat/" + Mutation.SET_ADD_CHAT_MESSAGE)) {
            setTimeout(function() { $(".chat-body").scrollTop($(".chat-body")[0].scrollHeight) ; }, 200);
        }
        if (mutation.type === ("aichat/" + Mutation.SET_ITEM) && mutation.payload.field == 'waitingReply') {
            setTimeout(function() { $(".chat-body").scrollTop($(".chat-body")[0].scrollHeight) ; }, 200);
        }
        if (mutation.type === ("aichat/" + Mutation.SET_ITEM) && mutation.payload.field == 'userMessage') {
            $("textarea#usermessage").height(15);
            if(mutation.payload.value!='') {
                $("textarea#usermessage").height($("#usermessage").prop('scrollHeight') - 4);
            }
        }
        if (mutation.type === ("aichat/" + Mutation.SET_ITEM) && mutation.payload.field == 'waitingReply') {
            if(mutation.payload.value==false) {
                setTimeout(function() { $("textarea#usermessage").focus();},20);
            }
        }
    });
}

export default [
    addNewMessageSideEffects
];