import Mutation from './mutationTypes';
import { apiLoadTOC, apiGetSecitonDiffSummary, apiLoadPromptOptions, apiUpdateSummaryRating, apiCheckDiffSummaryStatus, apiSaveDiffSummaryByJobId  } from "@/components/AICompare/api"

export default {
    setItem: ({ commit }, {field, value}) => {
        commit(Mutation.SET_ITEM, {field, value});
    },
    initComparePDF: async ({ commit }, compareDetails) => {
        if(compareDetails.userId != 'undefined' && compareDetails.userId) {
            try {
                commit(Mutation.SET_ITEM, {field: 'showCompareWindow', value: true});    
                commit(Mutation.SET_ITEM, {field: 'showCompareSummary', value: false});
                commit(Mutation.SET_ITEM, {field: 'showTOCSelection', value: false});
                commit(Mutation.SET_ITEM, {field: 'toc1Selection', value: {}});
                commit(Mutation.SET_ITEM, {field: 'toc2Selection', value: {}});
                commit(Mutation.SET_ITEM, {field: 'showError', value: false});  
                commit(Mutation.SET_ITEM, {field: 'showTOCError', value:  false});  
                commit(Mutation.SET_ITEM, {field: 'errMessage', value: false});    
                commit(Mutation.SET_ITEM, {field: 'itemId1', value: compareDetails.itemId1});
                commit(Mutation.SET_ITEM, {field: 'itemId2', value: compareDetails.itemId2});
                commit(Mutation.SET_ITEM, {field: 'itemName1', value: compareDetails.itemName1});
                commit(Mutation.SET_ITEM, {field: 'itemName2', value: compareDetails.itemName2});
                commit(Mutation.SET_ITEM, {field: 'language', value: compareDetails.language});
                commit(Mutation.SET_ITEM, {field: 'docName', value: compareDetails.docName});
                commit(Mutation.SET_ITEM, {field: 'userId', value: compareDetails.userId});
                commit(Mutation.SET_ITEM, {field: 'docId', value: compareDetails.docId});
                commit(Mutation.SET_ITEM, {field: 'diffSummaryId', value: 0});
                commit(Mutation.SET_ITEM, {field: 'selectedRating', value: 0});
                commit(Mutation.SET_ITEM, {field: 'loading', value: true});
                commit(Mutation.SET_ITEM, {field: 'initLoading', value: true});
                let loadTOCApiAbortController =  new AbortController();
                commit(Mutation.SET_ITEM, {field: 'abortController.loadTOCApi', value: loadTOCApiAbortController});
                const toc =  await apiLoadTOC(compareDetails, loadTOCApiAbortController);
                if(toc && toc.length > 0) {
                    toc.forEach((tocEle) => {
                        if(tocEle.uid == compareDetails.itemId1) {
                            commit(Mutation.SET_ITEM, {field: 'toc1', value: tocEle});
                        }
                        if(tocEle.uid == compareDetails.itemId2) {
                            commit(Mutation.SET_ITEM, {field: 'toc2', value: tocEle});
                        }
                    });
                    const promptOptions =  await apiLoadPromptOptions();
                    if(promptOptions.prompts) {
                        promptOptions.prompts.unshift({id: 0, title: 'Select response format...'})
                        commit(Mutation.SET_ITEM, {field: 'promptOptions', value: promptOptions.prompts});
                    }
                    commit(Mutation.SET_ITEM, {field: 'showTOCSelection', value: true});
                    commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                    commit(Mutation.SET_ITEM, {field: 'initLoading', value: false});
                } else {
                    commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                    commit(Mutation.SET_ITEM, {field: 'initLoading', value: false});
                    commit(Mutation.SET_ITEM, {field: 'errMessage', value: "Cannot provide the compare feature for these pdf.<br>The table of content is not provided in the pdf."});
                    commit(Mutation.SET_ITEM, {field: 'showError', value:  true});
                    commit(Mutation.SET_ITEM, {field: 'showTOCError', value:  true});
                    commit(Mutation.SET_ITEM, {field: 'showCompareSummary', value: false});
                    commit(Mutation.SET_ITEM, {field: 'showTOCSelection', value: false});
                }
            } catch(error) {
                commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                console.log('api call failed for getting previous chat list');
                console.log(error);
            }
          
            commit(Mutation.SET_ITEM, {field: 'loading', value: false});    
        }
    },
    getSecitonDiffSummary: async ({ commit, getters }, compareParams) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'showCompareSummary', value: false});
            commit(Mutation.SET_ITEM, {field: 'showTOCSelection', value: false});
            commit(Mutation.SET_ITEM, {field: 'loading', value: true});
            let compareApiAbortController =  new AbortController();
            commit(Mutation.SET_ITEM, {field: 'abortController.compareApi', value: compareApiAbortController});
            const jobId =  await apiGetSecitonDiffSummary(compareParams, compareApiAbortController);
            const updateStateForError = () => {
                commit(Mutation.SET_ITEM, {field: 'loading', value: false});
                commit(Mutation.SET_ITEM, {field: 'errMessage', value: "The service seems to timeout. You can retry by clicking back button.<br>Select the different response format or select the subsection instead of entire section<br>and regenerate the difference summary."});
                commit(Mutation.SET_ITEM, {field: 'showError', value:  true});
                commit(Mutation.SET_ITEM, {field: 'showCompareSummary', value: false});
                commit(Mutation.SET_ITEM, {field: 'showTOCSelection', value: false});
            }
            if(jobId) {
                //check if diff available every 5 sec
                let count = 0;
                let checkDiffSummaryStatusCallback = async function() {
                    if(getters.getItem('processAborted')) { //if the compare has been canceled by click back or close button do nothing.
                        return;
                    }
                    count++;
                    if(count > 15) {
                        updateStateForError();
                        return ;
                    }
                    let checkDiffStatusApiAbortController =  new AbortController();
                    commit(Mutation.SET_ITEM, {field: 'abortController.checkDiffStatusApi', value: checkDiffStatusApiAbortController});
                    const response = await apiCheckDiffSummaryStatus(jobId, checkDiffStatusApiAbortController);
                    if(response.status) {
                        switch(response.status) {
                            case 'PENDING':
                            case 'PROCESSING':
                                setTimeout(checkDiffSummaryStatusCallback, 5000);
                                break;
                            case 'FINISHED':
                                const response = await apiSaveDiffSummaryByJobId(jobId);
                                if(response && response.aiMessage && response.id) {
                                    //when finished, call the api to save the response
                                    commit(Mutation.SET_ITEM, {field: 'diffSummary', value: response.aiMessage});
                                    commit(Mutation.SET_ITEM, {field: 'diffSummaryId', value: response.id});
                                    commit(Mutation.SET_ITEM, {field: 'showCompareSummary', value: true});
                                    commit(Mutation.SET_ITEM, {field: 'loading', value: false});    
                                } else {
                                    updateStateForError();
                                }
                                break;
                            case 'ERROR':
                            default:
                                updateStateForError();
                            break;
                        }
                    }
                }
                setTimeout(checkDiffSummaryStatusCallback, 10000);
            } 
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'loading', value: false});
            console.log('api call failed for getting reply');
            console.log(error);
        }
    },    
    updateSummaryRating: async ({ commit },{ summaryId, rating }) => {
        try {
            commit(Mutation.SET_ITEM, {field: 'updateRatingInProgress', value: true});
            const reply =  await apiUpdateSummaryRating({ summaryId, rating });
            commit(Mutation.SET_ITEM, {field: 'updateRatingInProgress', value: false});
        } catch(error) {
            commit(Mutation.SET_ITEM, {field: 'updateRatingInProgress', value: false});
            console.log('api call failed for getting reply');
            console.log(error);
        }
    },    
    closeCompare:  ({ commit }) => {
        commit(Mutation.SET_ITEM, {field: 'showCompareWindow', value: false});    
    }
}