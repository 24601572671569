import axios from "@/plugins/axios";

export const apiLoadTOC = async ({itemId1, itemId2, userId}, loadTOCApiAbortController) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}, signal: loadTOCApiAbortController.signal};
        params = new URLSearchParams();
        params.append('itemIds[]', itemId1);
        params.append('itemIds[]', itemId2);
        if(typeof(userId) != 'undefined' && userId != null && userId != '') {
            params.append('userId', userId);
        }

        const apiEndPoint = 'getPdfTOC';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options

        });
        if (response.data) {
            if (response.data.status === 'ok') {
                return response.data.data;
            } else {
                console.log('There has been a problem loading TOC for sections selection.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:' + e.message);
    }
    return [];
}

export const apiGetSecitonDiffSummary = async (compareParams, compareApiAbortController) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}, signal: compareApiAbortController.signal};
        params = new URLSearchParams();
        params.append('compareParams', JSON.stringify(compareParams));
        const apiEndPoint = 'getSectionDiffSummary';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options
        });
        if (response.data && response.data.data) {
            if (response.data.status === 'ok' && typeof(response.data.data.jobId)!='undefined') {
                return response.data.data.jobId;
            } else {
                console.log('There has been a problem with you request to create diff summary job.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with you request to create diff summary job:' + e.message);
    }
    return false;
}



export const apiLoadPromptOptions = async () => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        const apiEndPoint = 'getPrompts';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options

        });
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your fetch operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:' + e.message);
    }
    return [];
}


export const apiUpdateSummaryRating = async ({ summaryId, rating }) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('summaryId', summaryId);
        params.append('rating', rating);
        const apiEndPoint = 'updateSummaryRating';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options
        });
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your fetch operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:' + e.message);
    }
    return [];
}



export const apiCheckDiffSummaryStatus = async (jobId, checkDiffStatusApiAbortController) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}, signal: checkDiffStatusApiAbortController.signal};
        params = new URLSearchParams();
        params.append('jobId', jobId);
        const apiEndPoint = 'checkDiffSummaryStatus';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options
        });
        if (response.data && response.data.data) {
            if (response.data.status === 'ok' && typeof(response.data.data)!='undefined') {
                return response.data.data;
            } else {
                console.log('There has been a problem with you request to check diff summary status.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with you request to check diff summary status:' + e.message);
    }
    return [];
}

export const apiSaveDiffSummaryByJobId = async (jobId) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('jobId', jobId);
        const apiEndPoint = 'savePromptResultByJobId';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options
        });
        if (response.data && response.data.data) {
            if (response.data.status === 'ok' && typeof(response.data.data)!='undefined') {
                return response.data.data;
            } else {
                console.log('There has been a problem with saving the response.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with saving the response:' + e.message);
    }
    return [];
}
