export default () => ({
    waitingReply: false,
    chatMessages: [],
    showMainChatWindow: false,
    showChatMessageWindow: true,
    userMessage: '',
    previousChatList: {},
    loading: false,
    docId: '',
    itemId: '',
    userId: '',
    chatId: '',
    resId: '',
    pubId: ''
});